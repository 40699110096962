// JEREMIAS <- FIBEL SKIN <- BLUMFELD SKIN
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
}

.container {
  // padding-left: 50px;
  // padding-right: 50px;
}

.invisible {
  display: none;
}

// NAV
#header {
  @extend .sticky-top;
  transition: transform 0.15s ease-out;
  border-bottom: 0.1rem solid rgba($basetext, 0.08);
  background-color: $bgwhite !important;
  z-index: 2;

  .navbar {
    @extend .container;

    // max-width: 1000px;

    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    @include media-breakpoint-down(sm) {
      padding-top: 0.6rem !important;
      padding-bottom: 0.6rem !important;
    }
  }

  .bg-light {
    background-color: $bgwhite !important;
  }
}
body[data-scrolled] {
  #header {
    transform: translateY(-100%);
  }
}
body[data-scrollingup],
body[data-menuopen][data-scrolled] {
  #header {
    transform: translateY(0%);
  }
}

#header .navbar {
  .navbar-brand {
    img {
      max-width: 130px;
      padding-left: 0;
      // height: 2.5rem;
      // max-height: 2.5rem;

      &:hover,
      &:focus {
        // filter: opacity(0.66);
      }
    }
    @include media-breakpoint-down(sm) {
      img {
        // max-width: 75px;
      }
      // margin: 0;
      // height: 3.5rem;
      // max-height: 3.5rem;
    }
  }
}

#header {
  // big screens
  .main-menu {
    // hide the main menu hamburger
    display: none !important;
  }
  .navbar .secondary-menu {
    justify-content: space-between;
    padding-left: 1.5rem;
    .nav-item.last a {
      padding-right: 0;
    }

    .navbar-nav {
      color: $primary;
      font-weight: 500;
      font-size: large;
    }
  }

  // small screens
  @include media-breakpoint-down(sm) {
    .main-menu {
      display: flex !important;
    }

    .navbar .secondary-menu {
      justify-content: space-between;
      flex-grow: 0;
      padding-left: 0;

      .menu {
        // hide category links
        display: none;
      }
      .navbar-nav {
        color: $primary;
        font-weight: 500;
        font-size: large;
      }
    }
    .flex-nowrap {
      justify-content: space-between;
    }
  }
}

// body[data-scrolled] {
//   #header:has(#cart-menu.open),
//   #header:has(#main-menu.open) {
//     opacity: 1 !important;
//     transform: translateY(0px);
//   }
// }

.navbar {
  .hamburger {
    @include media-breakpoint-down(sm) {
      padding-left: 0px;
    }
  }

  .hamburger:hover {
    transform: scale(1.1) !important;
    transition: transform 0.15s ease, opacity 0.15s ease;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  //margin-left: 20px;
  min-width: 70px !important;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

#header .navbar .secondary-menu .navbar-nav {
  .nav-item a {
    font-size: $font-size-base;
    color: rgba(18, 18, 18, 0.75) !important;

    &:hover,
    &.active {
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      color: rgb(18, 18, 18) !important;
    }
  }
}

.offcanvas-collapse {
  background-color: rgba($bgwhite, 0.95);
  top: 89px;
  box-shadow: none;
  @include media-breakpoint-down(sm) {
    top: 62px;
  }
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    //background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      color: rgba(18, 18, 18, 0.75) !important;
    }
    a.active,
    a:hover {
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      color: rgb(18, 18, 18) !important;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    //text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
  .nav-item:hover {
    background-color: transparent;
  }
}

.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// MAIN MENU
#main-menu {
  min-width: 90vw;
  height: 100vh;
  padding-top: 3rem;
  border-right: 0.1rem solid rgba($basetext, 0.08);
}

// OC CART
.offcanvas-collapse-right {
  width: min(90vw, 480px);
  height: 100vh !important;
  padding-top: 1rem;
  margin-left: 2rem;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  border-left: 0.1rem solid rgba($basetext, 0.08);
  h5 {
    font-size: $h5-font-size;
    // text-transform: uppercase;
    color: $basetext !important;
  }
}

// MAKI
.masonry.row {
  margin: 0;
}

.sushi-container {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.maki.card {
  overflow: hidden;

  .badge-container {
    top: 10px;
    right: 10px;
  }

  .badge {
    color: $white !important;
    padding: 0.5rem 1rem;
    border-radius: 4rem;
    font-size: 0.8rem !important;
    font-weight: 700 !important;
    letter-spacing: 0.05rem;
  }

  .card-body {
    height: 8.4rem;
  }

  .card-title {
    text-align: left;

    .title {
      font-weight: 500 !important;
      padding-bottom: 0.8rem;
      font-size: 13px;
    }

    .sub-title {
      font-size: smaller;
    }

    .price {
    }

    .former-price {
      text-decoration: line-through;
      //font-size: 13px;
      //line-height: 1.6rem;
      color: rgba(18, 18, 18, 0.75) !important;
    }
  }
  .image-container {
    overflow: hidden;
  }
  // trying to control row placement
  .card-title {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    .date-container {
      display: none;
    }
    .title {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  // TAGS
  .tag {
    position: absolute;
    bottom: 9rem;
    @include media-breakpoint-up(lg) {
      bottom: 9rem;
    }
    left: 10px;
    padding: 0.5rem 1rem;
    border-radius: 4rem;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
  }

  &:hover {
    .title {
      text-decoration: underline;
    }
    .maki-img {
      transform: scale(1.03);
      transition: transform 0.5s ease;
    }
  }
}

// PDP

.detail-page {
  .super-title {
    font-size: 0.7rem;
    letter-spacing: 1.3px;
    color: rgba(18, 18, 18, 0.75) !important;
    opacity: 1;
  }

  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.4;
    span {
      font-size: 40px;
      letter-spacing: 0.6px;
      line-height: 52px;
      font-weight: 500;
    }
  }
  .variant-chooser {
    text-align: left !important;
  }
  small.former-price {
    text-decoration: line-through;
    color: rgba(18, 18, 18, 0.75) !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.3px;
    line-height: 24px;
    margin-right: 10px;
  }
  .price {
    display: block;
    text-align: left !important;
    font-size: 18px;
    letter-spacing: 1.3px;
    line-height: 27px;
    @extend .mt-3;
  }

  div[itemprop='description'] {
    p {
      //color: rgba(18, 18, 18, 0.75) !important;
      // font-size: 16px !important;
      // font-style: normal !important;
      // font-weight: 500 !important;
      // letter-spacing: 0.6px !important;
      // line-height: 28.8px !important;
    }
  }

  button.add-product {
    align-items: center;
    appearance: none;
    background-color: rgb(241, 239, 237);
    border: rgb(18, 18, 18) 1px solid;
    box-sizing: border-box;
    color: rgb(18, 18, 18);
    // display: flex;
    // justify-content: center;
    letter-spacing: 1px;
    // margin-bottom: 10px;
    // padding-bottom: 0px;
    // padding-left: 30px;
    // padding-right: 30px;
    // padding-top: 0px;
    text-transform: initial;
  }

  .carousel-indicators {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;

    button {
      width: 49%;
      border: 0px solid transparent;

      .product-thumb {
        height: 100% !important;
        width: 100% !important;
        transform: translate(-5px, -5px);
        margin: 0;
      }
    }
  }

  // .variant-chooser-quantity {
  //   border: rgb(18, 18, 18) 1px solid;

  //   button {
  //     align-items: center;
  //     background-color: rgba(0, 0, 0, 0);
  //     color: rgb(18, 18, 18);
  //     display: flex;
  //     font-size: 18px;
  //     font-weight: 400;
  //     justify-content: center;
  //     margin-left: 1px;
  //     padding-bottom: 0px;
  //     padding-left: 0px;
  //     padding-right: 0px;
  //     padding-top: 0px;
  //   }
  // }

  @include media-breakpoint-down(md) {
    header {
      text-align: left !important;

      .super-title.text-muted,
      .product-header-title {
        text-align: left !important;
      }
      .product-header-title {
        font-weight: 500;
        color: rgb(18, 18, 18);
      }
    }
    .price,
    h2.product-title {
      text-align: left !important;
    }
    .price {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1.3px;
      color: rgb(18, 18, 18);
    }
    .variant-chooser-quantity,
    .variant-chooser-cta {
      flex: 1 1 100% !important;
    }
  }
}

.btn.btn-primary {
  appearance: none;
  background-color: rgb(241, 239, 237);
  border: rgb(18, 18, 18) 1px solid;
  color: rgb(18, 18, 18);
  font-weight: 500;
  letter-spacing: 1px;
  &:hover {
    //border: rgb(18, 18, 18) 2px solid;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $bgwhite;
  color: $primary;
  min-height: unset;
  border-top: 0.1rem solid rgba($basetext, 0.08);
  p,
  li {
    // opacity: 0.8;
  }
}

#product-listing {
  padding-top: 2rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// header image
.header-image {
  background-image: url('./assets/images/ticketheader.jpg');
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 50vh;

  @include media-breakpoint-down(sm) {
    height: 390px;
  }

  // img {
  //   width: 100%;
  // }
}

// various
.simple-cart .icon svg {
  // height: 20px !important;

  &:hover {
    transform: scale(1.07) !important;
  }
}
.navbar-light .navbar-nav .nav-link svg.icon {
  color: rgb(18, 18, 18);
}
.icon-cart {
  height: 27px !important;
  width: auto;
  transform: translateY(-3px);
  padding-left: 0 !important;
}
.icon-account {
  height: 22px !important;
  transform: translateY(0px);
}

span.item-count {
  background-color: $warning;
  transform: scale(0.7) translateX(63px);
  color: black !important;
  z-index: 1;
  position: absolute;
  right: 22px;
  bottom: 28px;
  font-size: 9px;
  @include media-breakpoint-down(sm) {
    // right: 30px;
    bottom: 14px !important;
  }
}

span.expiration {
  display: none;
}

// versandart wählen
.checkout {
  .shipping-method-list,
  .payment-method-list {
    label {
      background: none !important;
    }
    label.active {
      color: rgb(18, 18, 18) !important;
    }
  }
}

// remove borders on tt-listing
.tt-listing {
  th,
  td {
    border-bottom: none !important;
  }
}

// maybe also on modals
.modal {
  .modal-header,
  .sticky-bottom {
  }
}

.ticket-detail-page {
  .blurry-background-image {
    height: 50vh;
    background-color: transparent;
  }

  .blurry-background-image:before {
    filter: blur(0rem);
    opacity: 1;
    background-position: center center;
  }

  // .blurry-background-image:before {
  //   background-image: none;
  // }
  .carousel-inner {
    display: none !important;
  }

  .article-title {
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    border: 0;
    border-bottom: 0.1rem solid rgba($basetext, 0.08);

    .super-title {
      font-size: 0.7rem;
      letter-spacing: 1.3px;
      color: rgba(18, 18, 18, 0.75) !important;
      opacity: 1;
    }
  }

  .product-info-bar {
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid rgba($basetext, 0.08);
    box-shadow: none;
    .navbar-text {
      text-align: left;
      margin-left: 0;
      display: block;
    }
  }
}

// make category detail look like product listing
body.category-detail-page {
  .sushi-container {
    @extend .col-sm-12, .col-md-12, .col-lg-3;
  }

  article.category-detail {
    margin-bottom: 0 !important;
    section.container {
      padding-top: 0 !important;
    }
  }
}

// CUSTOM LIST STEPS

ol.list-steps {
  $active: $black;
  $activatable: $black;
  $width: 0.75rem;
  $border-width: 0.125rem;
  $border-lighten: 10%;

  padding: 0;
  margin: 0;
  counter-reset: steps;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  position: relative;
  margin: 3rem 0;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: rgba($body-color, 0.5);

    &.active {
      color: $body-color;
    }

    &::before {
      counter-increment: steps;
      content: '';
      display: inline-block;
      // background: $secondary;
      z-index: 1;
      background: darken($bgwhite, $border-lighten);
      color: color-yiq($bgwhite);
      border-radius: 50%;
      border: $border-width solid darken($bgwhite, $border-lighten);
      width: $width;
      height: $width;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    &.activatable::before {
      background: $black;
      color: color-yiq($activatable);
      border: $border-width solid lighten($activatable, $border-lighten);
    }

    &.active::before {
      background: $active;
      color: color-yiq($active);
      border: $border-width solid lighten($active, $border-lighten);
      animation: pulsate 1s infinite ease-in-out alternate;
    }

    flex: 1 1 100%;
    &::after {
      position: absolute;
      z-index: 0;
      top: ($width - $border-width) / 2;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: $border-width;
      background: darken($bgwhite, $border-lighten);
    }
    &.active::after {
      background: lighten($active, $border-lighten);
    }
    &.activatable::after {
      background: lighten($activatable, $border-lighten);
    }
    &:first-child::after {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-child::after {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  @keyframes pulsate {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.3);
    }
  }
}

// Stage

.stage {
  //background-color: #55a5ca;
  // min-height: 25vh;
  .container {
    padding: 0;
  }
}

// Newsletter Checkbox bold text
label[for='allow_promotion'] {
  font-weight: 700;
}

// blue shopping cart logo
.icon-cart,
.fa-shopping-bag {
  color: #134eb8 !important;
}

.fa-shopping-bag::before {
  color: transparent;
  background-image: url('./assets/images/shopping-bag.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.detail-page button.add-product {
  color: #134eb8 !important;
  border-color: #134eb8 !important;
  box-shadow: rgba(19, 78, 184, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
